import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import { Row, Col } from 'antd';
import Projects from "../components/Projects";

const fociPage = () => {
    return (
      <Layout>
        <div className="index-container" > 
          <Helmet title={config.siteTitle} />
          <SEO />
          <Row>
            <h1 style={{textAlign: `center`, marginBottom: `0`}}>Projects</h1>
            <Projects />
          </Row>
          </div>
      </Layout>
    )
  }

  export default fociPage;